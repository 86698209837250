// import React from "react";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import BlogFeed from "pages/BlogFeed";
import RelatedArticle from "../blogs/ThreeColSimpleWithTopImage.js";
import { Timer, CheckCircle2, XCircle } from "lucide-react";
import QuizProgressChart from "./quizprogresschart.js";
import md5 from "md5";
import LoginModal from "pages/loginModal.js";
import ThreeCharts from "./chart.js";
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
const Container = tw.div`relative bg-white lg:px-0 xs:px-2`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-row items-center md:items-stretch md:flex-row flex-wrap md:justify-start py-10 md:py-4`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-left`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-3/4`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-black leading-loose`}
  }
`;
const ParaBlock = styled.div`
  ${tw`w-full mb-6`}
`;
const Title = styled.h4`
  ${tw`text-xl font-bold text-gray-900 text-left`}
`;

const QuizContainer = styled.div`
  ${tw`w-full mx-auto p-6 bg-white rounded-lg shadow-md`}
`;

const QuestionContainer = styled.div`
  ${tw`mb-6`}
`;

const QuestionTitle = styled.h2`
  ${tw`text-xl font-semibold text-gray-900 mb-4`}
`;

const OptionsContainer = styled.div`
  ${tw`space-y-3`}
`;

const OptionLabel = styled.label`
  ${tw`flex items-center p-3 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200`}
`;
const QuestionText = styled.h3`
  ${tw`text-xl font-semibold text-gray-900 mb-4`}
`;

const OptionsList = styled.div`
  ${tw`flex flex-col space-y-2`}
`;

// const OptionLabel = styled.label`
//   ${tw`flex items-center bg-gray-200 p-4 rounded-lg cursor-pointer hover:bg-gray-300`}
//   width: 90%;
// `;

const OptionCheckbox = styled.input`
  ${tw`mr-4`}
`;

const ButtonContainer = tw.div`flex space-x-4 mt-6`;

const ActionButton = styled.button`
  ${tw`mt-6 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-700`}
`;

const TimerContainer = styled.div`
  ${tw`flex items-center gap-2 mb-6`}
`;

const SolutionContainer = styled.div`
  ${tw`mt-6 space-y-4`}
`;

const AnswerStatus = styled.div`
  ${tw`flex items-center gap-2`}
`;

const SolutionBox = styled.div`
  ${tw`bg-gray-500 p-4 rounded`}
`;
const FillInBlankInput = styled.input`
  ${tw`p-3 border rounded-lg`} /* Removed w-full to avoid conflict */
  width: 90%;
  margin: 0 auto; /* Centers the input horizontally */
`;

// const ButtonContainer = tw.div`flex space-x-4 mt-6`;
// const ActionButton = styled.button`
//   ${tw`px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-700`}
// `;
const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Paragraph = styled.div`
  ${tw`w-full text-lg leading-loose text-gray-600 font-medium`}
  .blogContainer {
    ${tw`lg:pr-10 px-2`}
  }
  html {
    font-size: 14px; /* 1rem = 13px */
  }
  h1 {
    ${tw`mb-2 leading-tight !text-[#f20e80]`};
    font-size : 1.5rem;
    margin-top: 4.0rem;
  }
  h2 {
    ${tw`mb-2 leading-tight`};
    color: #597db6;
    font-size : 1.2rem;
    font-weight : bold;
    margin-top: 2.0rem;
  }
 
 h3 {
    ${tw`mt-2 mb-4 leading-tight`};
    color: #0b1320;
    font-size : 1.2rem;
    font-weight: bold;
    border-bottom: 2px solid #0b1320;
    padding-bottom: 5px

  }
  h4 {
    ${tw`mt-2 mb-4 !text-black leading-tight`};
    color : #0b1320;
    font-size : 1.2rem
  }
  h5 {
    ${tw`mt-2 mb-4 !text-black leading-tight`};
    color : #0b1320;
    font-size : 1.0rem;
  }
  h6 {
    ${tw`mt-2 mb-4 !text-black leading-tight`}
    color : #0b1320;
    font-size : 1.0rem;
  }
  ul {
    list-style: disc;
    margin-left: 19px;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  li {
    color: #0b1320;
    list-style:none;
    font-weight: 500;
    position: relative;
    padding-left: 15px;
  }
  ul li::before {
    content: '*';
    position: absolute;
    top: 6px;
    left: 0;
  }
  .blogContainer p {
    ${tw`mb-4 text-[#191C1F] leading-7 text-justify`}
  }
  .blogContainer strong {
    ${tw`!font-semibold !text-black`}
  }
  .blogContainer span {
    ${tw`font-bold !text-brand-500 text-lg`}
  }
  .blogContainer img {
    ${tw`w-auto my-6`}
  }
  table {
  width:100%;
  border-collapse: collapse;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-bottom: 15px;
  }
  
/* Styles for small devices */
@media (max-width: 600px) {
  table {

    display:block;             /* Treat the table as a block element */
    overflow-x: auto;           /* Enable horizontal scrolling when needed */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    border-collapse: collapse;
    border:1px solid #dbdbdb;
    border-radius:5px !important;
    margin-bottom:15px;
  }
}

  thead{
    background:#22242d;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
    border-left:1px solid #dbdbdb;
    padding-left:15px;
    min-width:110px;
    max-width:250px;
    
  }

  
  thead tr td p{
  color: #fff !important;
  text-align:left !important;  
  }

  tbody tr td p{
    text-align:left !important;
  }

  /*tbody tr:first-child {
    background:#22242d;
  }  */

  table > tbody:only-child tr:first-child {
  background: #22242d;
  }

  table > tbody:only-child tr:first-child td p {
  color: #fff !important;
  text-align:left !important;  
  }

 /* tbody tr:first-child td p {
    color: #fff !important;
  text-align:left !important;  
  }
  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background:inherit
  } */

  tr:nth-child(even) {background-color: #f2f2f2;}
  ol{
    display:block;
  }
  ol li {
    padding: 7px 15px;
    font-size: 1.125rem;
    font-weight: 500;
    background:#fafafa;
    color: #f49868;
  }
`;


const TwoColumn = tw.div`lg:flex lg:flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto xs:py-10 md:py-2`;
const LeftColumn = tw.div`-mt-16 relative lg:w-[75%] pt-4 text-left max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative p-5 bg-[#f5f5f5] mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-start`;
/*const RightColumn = styled.div`
  ${tw`bg-[#f5f5f5] md:w-1/2 lg:w-1/4 py-4 px-8`}
`;
*/
const Sectionhead = styled.h2`
  ${tw`!text-xl font-semibold text-gray-900 text-left mb-6`}
`;
const LinkBlock = styled.div`
  ${tw`flex flex-col space-y-4 `}
`;
const Link = styled.a`
  ${tw`text-[#121a23] border-b-4 leading-loose`}
  .selected_topic a{
    color: #597db6;
    font-weight: bold;
  }
`;

const LoginModalopen = ({ onClose, onSuccessfulLogin }) => {
  return (
    <div tw="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto p-4">
    <div tw="bg-white w-[50vw] max-h-[80vh] rounded-lg relative overflow-y-auto">
      <button 
        tw="absolute top-0 right-0 m-4 text-gray-600 hover:text-red-900"
        onClick={onClose}
      >
        Close ×
      </button>
      <div tw="p-6">
        <LoginModal onSuccessfulLogin={onSuccessfulLogin} />
        </div>
      </div>
    </div>
  );
};

export default ({
  data,
  blog_id,
  relatedData,
  cards = null,
  blogProds,
  blogBanners,
  heading = "Our Offices",
  subheading = "Locations",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
}) => {
  //console.log(data);

  const defaultCards = [
    {
      title: "Branding",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Scalable",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Reliable",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Engaging",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Customizable",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
    {
      title: "Fast",
      description:
        "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!",
    },
  ];

  if (!cards) cards = defaultCards;
  /*{data &&(
              <div  dangerouslySetInnerHTML={{ __html: data }}></div>
            )}  */
  const keys = [
    "email",
    "hash",
    "userId",
    "name",
    "someId",
    "createdAt",
    "isActive",
    "phone",
    "flag1",
    "flag2",
    "expiryDate",
    "actionType",
    "flag3",
    "flag4",
    "flag5",
    "flag6",
  ];

  const [isStarted, setIsStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [timer, setTimer] = useState(0);
  const [attempts, setAttempts] = useState([]);
  const [topicId, setTopicId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [TopicMatric, setTopicMatric] = useState(null);
  const [userMatric, setuserMatric] = useState(null);
  const [quesMatric, setquesMatric] = useState(null);
  const [checkedans, setcheckedans] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(1); // Initialize the index to 0
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const [nextqueArray, setnextqueArray] = useState([]);

  console.log("attempts", attempts);



    const [extractedHTML, setExtractedHTML] = useState("");

  // Event handler for clicks on the blog container
  const handleHeadingClick = (event) => {
    // Process only h2 tag clicks
    if (event.target.tagName.toLowerCase() !== "h2") {
      return;
    }
    console.log(event);
    // Get the clicked h2 element
    const clickedHeading = event.target;

    // Find the closest container (optional, but useful if you have nested containers)
    const container = clickedHeading.closest(".blogContainer");
    if (!container) return;

    // Create a Range to define the portion of HTML to extract
    const range = document.createRange();
    range.setStartBefore(clickedHeading);

    let currentElement = clickedHeading;
    let lastElementInSection = clickedHeading;

    // Traverse the siblings until we hit the next h1 or h2 tag
    while (currentElement.nextElementSibling) {
      const nextEl = currentElement.nextElementSibling;
      const tagName = nextEl.tagName.toLowerCase();

      // Stop if the next heading is encountered
      if (tagName === "h1" || tagName === "h2") {
        break;
      }

      // Update the last element in the section and move to the next element
      lastElementInSection = nextEl;
      currentElement = nextEl;
    }
    range.setEndAfter(lastElementInSection);



    




    // Clone the range's contents into a DocumentFragment
    
    const documentFragment = range.cloneContents();
    // Create a temporary container to convert the DocumentFragment into HTML
    const tempDiv = document.createElement("div");
    tempDiv.appendChild(documentFragment);
    const html = tempDiv.innerHTML;

    // Set the extracted HTML in state or process it further
    setExtractedHTML(html);
    console.log("Extracted HTML:", html);




    const wrapper = document.createElement("div");
    wrapper.style.backgroundColor = "lightblue";
    wrapper.style.padding = "10px";

    // Extract the range's contents.
    const extractedContents = range.extractContents();

    
    //Append the extracted content to the wrapper.
    wrapper.appendChild(extractedContents);

    //Insert the wrapper back into the DOM at the original range position.
    range.insertNode(wrapper);
  };










  const checkAuth = () => {
    const cookies = document.cookie.split(";");
    const adminCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("adminLoginData=")
    );
    console.log("cookiesdataaa", adminCookie);
    return !!adminCookie;
  };
  // const checkAuth = () => {
  //   const cookies = document.cookie.split(';');
  //   const adminCookie = cookies.find(cookie => cookie.trim().startsWith('adminLoginData='));
  //   console.log('cookiesdataaa',adminCookie)
  //   return !!adminCookie;
  // };

  // API Configuration
  const API_KEY = "ymcaftgtbln";
  const API_SECRET = "poolsecret@#skk";
  const BASE_URL = "https://youngminds.pro/olv/mobapp/user";

  useEffect(() => {
    let interval;
    if (isStarted && !showAnswer) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isStarted, showAnswer]);

  // Generate Hash
  const generateHash = (time, timeExp) => {
    return md5(`${time}${API_KEY}${API_SECRET}jhakkas${timeExp}`);
  };




  // Start Test API Call
  const handleStartTest = async () => {
    const time = Math.floor(Date.now() / 1000);
    // const timeExp = '15'//time + 3600;
    const timeExp = time + 15;
    const hash = generateHash(time, timeExp);

    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) =>
      row.startsWith(`${"adminLoginData"}=`)
    );
    const uid = cookie ? cookie.split("=")[1] : "0";
    console.log("uiddd", uid);

    const values = uid.split("|");
    const jsonObject = keys.reduce((acc, key, index) => {
      acc[key] = values[index] || null; // Handle cases where values are missing
      return acc;
    }, {});

    console.log(jsonObject);

    setUserId(jsonObject?.userId || uid);

    try {
      const response1 = await fetch(
        `${BASE_URL}/ymstarttest/${hash}/${time}/${timeExp}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            topic_id: "YMDEF", // Hardcoded for now
            uid: userId, // Assuming user is not logged in
          }),
        }
      );
      console.log("resposense1", response1);

      const response = await response1.json();
      console.log("response", response);
      setuserMatric(response?.user_metrics)
      setTopicMatric(response?.topic_metrics)
      setquesMatric(response?.ques_metrics)
      // const response = await axios.post(
      //   `${BASE_URL}/ymstarttest/${hash}/${time}/${timeExp}`,
      //   { uid }
      // );

      const { currentQues, topic_metrics, topicID, test_ques_arr } = response;
      console.log("test_ques_arr", test_ques_arr.split("--")); //test_ques_arr.split("--");
      setnextqueArray(test_ques_arr.split("--"));
      setCurrentQuestion(currentQues);
      setTopicId(topicID);
      setIsStarted(true);
    } catch (error) {
      console.error("Error starting test:", error);
      alert("Failed to start test. Please try again.");
    }
  };
  console.log("nextqueArray", nextqueArray);
  const handleOptionSelect = (option) => {
    if (!isStarted) return;
    setSelectedOption(option);
  };

  const handleViewAnswer = async () => {
    console.log("selectedoption", selectedOption);
    if (!selectedOption) return;

    const isCorrect = checkedans == currentQuestion.correctAns;
    console.log("hekckckck", checkedans, currentQuestion.correctAns);
    const status = isCorrect ? 1 : -1;

    setAttempts((prev) => [
      ...prev,
      {
        questionId: currentQuestion.questionId,
        timeTaken: timer,
        status,
        topic_id: currentQuestion.topicID,
      },
    ]);

    setShowAnswer(true);
  };

  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const handleSuccessfulLogin = () => {
    setIsLoginModalOpen(false);
    // Optionally, you can retry the next question logic here
    handleNextQuestion();
  };

  const handleNextQuestion = async () => {

    console.log('jsonObject',keys);
    // if (!checkAuth()) {
    //   const currentUrl = `${window.location.pathname}${window.location.search}`;
    //   window.location.href = `/login?redirect=${encodeURIComponent(currentUrl)}`;
    //   return;
    // }
    if (!checkAuth()) {
      setIsLoginModalOpen(true);
      return;
    }


    if (currentIndex >= nextqueArray.length) {
      alert("All questions have been attempted."); // Show alert when all questions are sent
      return;
    }

    const time = Math.floor(Date.now() / 1000);
    const timeExp = time + 15;
    const hash = generateHash(time, timeExp);

    try {
     
      const response1 = await fetch(
        `${BASE_URL}/getnxtq/${hash}/${time}/${timeExp}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uid: '10',
            topic_id: "YMDEF",
            qid_req: nextqueArray[currentIndex],
            qid_curr: currentQuestion.questionId,
            user_metric: attempts,
          }),
        }
      );
      console.log("resposense1", response1);

      const response = await response1.json();
      console.log("resposense", response);
      setquesMatric(response?.ques_metrics)
      const { currentQues } = response;
      setCurrentQuestion(currentQues);
      setSelectedOption(null);
      setShowAnswer(false);
      setTimer(0);
      setCurrentIndex(currentIndex + 1);
    } catch (error) {
      console.error("Error fetching next question:", error);
      alert("Failed to load next question. Please try again.");
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };
  const handleselected = (option, index) => {
    setSelectedOption(option);
    setcheckedans(index + 1);
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  
  return (
    <Container>
     {isLoginModalOpen && (
        <LoginModalopen 
          onClose={handleCloseLoginModal} 
          onSuccessfulLogin={handleSuccessfulLogin}
        />
      )}
      <TwoColumn>
        <LeftColumn>
        {/*} e is an inline equation: <strong><InlineMath math="\int_0^\infty e^{-x} \, dx = 1" /></strong>
           
           <BlockMath math="\displaystyle x = \frac{-b \pm \sqrt{b^2 - 4ac}}{2a}" />
           <InlineMath math="\displaystyle x = \frac{-b \pm \sqrt{b^2 - 4ac}}{2a}" />*/}
          <ParaBlock>
            
            <Paragraph >

              {data && (
           
                <div
                onClick={handleHeadingClick}
                  className="blogContainer"
                  dangerouslySetInnerHTML={{ __html: data }}
                ></div>
              )}
            </Paragraph>

          </ParaBlock>
        </LeftColumn>
        <RightColumn>
          <Sectionhead>Related Content</Sectionhead>
          <LinkBlock>
            {relatedData && (
              <>
                {relatedData.map((val) => (
                  
                  <Link href={val.link.toLowerCase()}
                    key={val.id}
                    style={val.id === blog_id ? { color: "#597db6", fontWeight: "bold" } : {}}

                  >
                    {val.title}
                  </Link>
                  
                ))}
              </>
            )}
          </LinkBlock>
        </RightColumn>
      </TwoColumn>
      <RelatedArticle blogBanners={blogBanners} blogProds={blogProds} />
      <DecoratorBlob />
    </Container>
  );
};
