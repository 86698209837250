const ResultScreen = ({ nextqueArray, attempts, userMatric1 }) => {
    const totalQuestions = nextqueArray.length;
    const correctCount = attempts.filter((a) => a.status === 1).length;
    const wrongCount = attempts.filter((a) => a.status === -1).length;
    const accuracy = ((correctCount / totalQuestions) * 100).toFixed(2);
    const totalScore = userMatric1.avg_score;
    const percentile = userMatric1.min_ptile;
    const avgTime = userMatric1.avg_time;
  
    return (
      <div style={styles.container}>
        <h2 style={styles.heading}>🎯 Test Results</h2>
        <div style={styles.resultBox}>
          <div style={styles.stat}>
            <span>Total Questions</span>
            <strong>{totalQuestions}</strong>
          </div>
          <div style={styles.stat}>
            <span>✅ Correct Answers</span>
            <strong style={{ color: "#28a745" }}>{correctCount}</strong>
          </div>
          <div style={styles.stat}>
            <span>❌ Wrong Answers</span>
            <strong style={{ color: "#dc3545" }}>{wrongCount}</strong>
          </div>
          <div style={styles.stat}>
            <span>🎯 Accuracy</span>
            <strong>{accuracy}%</strong>
          </div>
          <div style={styles.stat}>
            <span>🏆 Total Score</span>
            <strong>{totalScore}</strong>
          </div>
          <div style={styles.stat}>
            <span>📊 Percentile</span>
            <strong>{percentile}</strong>
          </div>
          <div style={styles.stat}>
            <span>⏳ Avg Time per Question</span>
            <strong>{avgTime} sec</strong>
          </div>
        </div>
        <button style={styles.button} onClick={() => window.location.reload()}>
          🔄 Restart Test
        </button>
      </div>
    );
  };
  
  // Inline Styles for Modern UI
  const styles = {
    container: {
      width: "80%",
      maxWidth: "500px",
      margin: "20px auto",
      padding: "20px",
      borderRadius: "12px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#f9f9f9",
      textAlign: "center",
    },
    heading: {
      fontSize: "22px",
      marginBottom: "15px",
      fontWeight: "bold",
      color: "#333",
    },
    resultBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    stat: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      fontSize: "16px",
      fontWeight: "500",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    },
    button: {
      marginTop: "15px",
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#007bff",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
      transition: "0.3s",
    },
  };
  
  export default ResultScreen;
  