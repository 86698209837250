
import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/aurumlogin.png";
import logo from "images/youngmindslogo.png";
import { serverUtilities } from "../services/serverutilities.service";
import { useHistory, useLocation } from 'react-router-dom';



const Container = tw.div`min-h-screen bg-white text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto my-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-brand-500 text-gray-100 w-full py-4 rounded-lg hover:bg-brand-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
`;
const GoogleButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-white text-gray-900 w-full py-4 rounded-lg hover:bg-gray-100 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none border border-gray-200`}
`;
const OrText = tw.div`my-6 text-center text-gray-500`;
const ErrorLabel = tw.p`text-red-700 text-sm mt-5`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-white text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-5 xl:m-5 w-full max-w-full bg-contain bg-center bg-no-repeat`}
`;

export default function OTPValidation() {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState("");
  const [otpTimestamp, setOtpTimestamp] = useState(null);
  const [msgVal, setError] = useState("");
  const history = useHistory();
  const location = useLocation();

  //console.log('redirecttttttttttttttttttt',history,location)


  const handleGoogleSignIn = () => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: '116348190984-gl1veflbtrp6flhoi1cbdeo7vapkm1o4.apps.googleusercontent.com',
      scope: 'email profile',
      callback: async (response) => {
        if (response.access_token) {
          console.log('respnse token',response)
          // Get user details using the access token
          try {
            const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
              headers: {
                'Authorization': `Bearer ${response.access_token}`
              }
            });
            const userInfo = await userInfoResponse.json();
            console.log('userInfo',userInfo)
            console.log('res_acc_token',response.access_token)
            
            serverUtilities.login('email-verified',userInfo.email,'12345').then(loginData=>{
              console.log('loginData',loginData);
              if(loginData.status == '0'){
        
                setError(loginData.message);
                
              }
              else
              {
                let value = loginData.cookieData;
                let name = "userLoginData";
                var date = new Date();
                date.setTime(date.getTime() + (6*60*60*1000));
                let expires = "; expires=" + date.toUTCString();
                document.cookie = name + "=" + (value || "")  + expires + "; path=/";
                // window.location.href = loginData.ur
                // l; //:w"https://aurumlabs.co/onlinelearning/admin/login";
                const redirectTo = location.state?.from || '/';
                const scrollPosition = location.state?.scrollPosition || 0;

                // Navigate back to the original page
                history.replace(redirectTo);
              }
            });
          
          } catch (error) {
            console.error('Error fetching user info:', error);
            setError("Failed to get user information");
          }
        }
      },
    });
  
    client.requestAccessToken();
  };
  const handleSendOtp = () => {
    if (!name || !email || !phoneNumber) {
      setError("Please fill all fields correctly");
      return;
    }

    const nameRegex = /^(?=.{5,}$)[A-Za-z\s]+$/;
    if (!nameRegex.test(name)) {
      setError("Name must be at least 5 Characters Long, No Special Characters.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a Valid Email Address");
      return;
    }

    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setError("Please enter a valid 10-digit Mobile Number");
      return;
    }


    setError("");
    const otp = Math.floor(1000 + Math.random() * 9000).toString();
    setGeneratedOtp(otp);
    setOtpTimestamp(Date.now());

    serverUtilities.sendOTP(phoneNumber, otp).then(response => {
      if (response.status === "0") {
        setError(response.message);
      } else {
        
        console.log(response); 
        console.log(`Generated OTP: ${otp}`);
        setStep(2);       
      }
    })

   
    

  };


  const handleVerifyOtp = () => {
    if (!otp) {
      setError("Please enter the OTP");
      return;
    }
    const currentTime = Date.now();
    if (currentTime - otpTimestamp > 5 * 60 * 1000) {
      setError("OTP has expired. Please request a new one.");
      return;
    }
    if (otp !== generatedOtp) {
      setError("Invalid OTP. Please try again.");
      return;
    }
    setError("");
    

    serverUtilities.registerUser(phoneNumber, name, "deviceId", email).then(response => {
      if (response.status === "0") {
        setError(response.message);
        setStep(1);
      } else {
        const cookieValue = response.cookieData;
        const name = "userLoginData";
        const date = new Date();
        date.setTime(date.getTime() + 6 * 60 * 60 * 1000);
        document.cookie = `${name}=${cookieValue}; expires=${date.toUTCString()}; path=/`;
        

        const redirectTo = location.state?.from || '/';
        const scrollPosition = location.state?.scrollPosition || 0;

        // Navigate back to the original page
        history.replace(redirectTo);
        

        setTimeout(() => {
          window.scrollTo(0, scrollPosition);
        }, 100);
        
        
      }
    });
  };

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href="/">
              <LogoImage src={logo} style={{ width: "auto", height: "120px" }} />
            </LogoLink>
            <MainContent>
              <Heading>User Sign In</Heading>
              {msgVal && <ErrorLabel>{msgVal}</ErrorLabel>}
              <FormContainer>
                {step === 1 && (
                  <>
                    <Input
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                    <Input
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <Input
                      type="text"
                      placeholder="Enter Phone Number For OTP"
                      value={phoneNumber}
                      onChange={e => setPhoneNumber(e.target.value)}
                    />
                    <SubmitButton onClick={handleSendOtp}>Send OTP</SubmitButton>
                    <OrText>or</OrText>
                    <GoogleButton onClick={handleGoogleSignIn}>
                      <img 
                        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                        className="mr-2 h-5 w-5"
                        alt="Google logo"
                      />
                      Continue with Google
                    </GoogleButton>
                  </>
                )}
                {step === 2 && (
                  <>
                    <span>{`Your OTP is: ${generatedOtp}`}</span>
                    <Input
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={e => setOtp(e.target.value)}
                    />
                    <SubmitButton onClick={handleVerifyOtp}>Verify OTP</SubmitButton>
                  </>
                )}
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustration} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
}